// import { memo, useContext, useEffect } from 'react';
// import bgMain2 from '@img/bg-main2.png';
// import leftCard from '@img/card-left.png';
// import centerCard from '@img/card-center.png';
// import rightCard from '@img/card-right.png';
// import ball from '@img/main-ball.png';
// import racket from '@img/racket.png';
// import { ROUTE_MY_TEAMS, ROUTE_TOURNAMENT } from '@constants/constants';
// import { Link, useNavigate } from 'react-router-dom';
// import { AuthContext } from '@context/AuthContext';
// import { useTranslation } from 'react-i18next';
// import { Fade } from 'react-awesome-reveal';

// const MainPage = memo(() => {
//   const { t } = useTranslation();
//   const { user } = useContext(AuthContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (user && !user?.is_learned) {
//       navigate(`${ROUTE_TOURNAMENT}`, { replace: true });
//     }
//   }, []);

//   return (
//     <main className="flex flex-col">
//       <section className="relative flex flex-col items-center justify-center h-[100vh] xl:h-screen gap-10 bg-transparent px-4 xl:px-0" data-aos="fade-up">
//         <Fade duration={1100} className="relative flex flex-col items-center justify-center h-[150vh] xl:h-screen gap-10 bg-transparent px-4 xl:px-0" cascade>
//           <ul>
//             <li className="flex flex-col gap-[10px] tracking-wide xl:text-9xl text-8xl font-bold text-center">
//               <p className="flex flex-col text-green">
//                 {' '}
//                 <p className='text-nowrap'>
//                   NFT{' '}
//                   <span className="text-white">TENNIS</span>
//                 </p>
//               </p>
//             </li>
//             <li className="max-w-screen-sm leading-6 text-center">
//               {' '}
//               <p>
//                 NFT TENNIS -
//                 {' '}
//                 {t('main.description')}
//               </p>
//             </li>

//             <li className="shadow-rainbow btn-white w-fit">
//               <Link
//                 to={`${ROUTE_MY_TEAMS}`}
//               >
//                 {t('main.create_team')}
//               </Link>
//             </li>
//           </ul>
//         </Fade>

//       </section>
//       <section
//         data-aos="fade-down"
//         className="bg-center bg-no-repeat bg-cover"
//         // style={{ backgroundImage: `url(${bgMain2})` }}
//       >
//         <div className="flex flex-col items-center gap-8 py-10">
//           <div className="flex flex-col items-center gap-8">
//             <p className="text-4xl">
//               {t('main.collection')}
//             </p>
//             <h2 className="text-5xl font-bold text-center">{t('main.digital_cards')}</h2>
//           </div>
//           <div className="grid items-center grid-cols-3 place-items-center">
//             <img src={leftCard} alt="left player card" className="" />
//             <img src={centerCard} alt="center player card" className="" />
//             <img src={rightCard} alt="right player card" className="" />
//           </div>
//           {/* <p className="text-center">{t('main.collect')}</p> */}
//         </div>
//       </section>
//       <section className="min-h-[100vh] flex flex-col justify-center p-4 pb-8" data-aos="zoom-in-up">
//         <div className="flex flex-col items-center xl:w-1/2 w-full m-[0_auto] gap-12">
//           <p className="text-green text-[45px] leading-[55px] text-center font-bold">
//             {t('main_about.about')}
//           </p>
//           <div className="flex flex-col gap-6 text-center">
//             <h2 className="text-3xl font-bold">
//               {t('main_about.collect')}
//             </h2>
//             <p className="text-standart">
//               {t('main_about.collect_desc')}
//             </p>
//           </div>
//           <div className="flex flex-col gap-6 text-center">
//             <h2 className="text-3xl font-bold">
//               {t('main_about.participate')}
//             </h2>
//             <p className="text-standart">
//               {t('main_about.participate_desc')}
//             </p>
//           </div>
//           <div className="flex flex-col gap-6 text-center">
//             <h2 className="text-3xl font-bold">
//               {t('main_about.compete')}
//             </h2>
//             <p className="text-standart">
//               {t('main_about.compete_desc')}
//             </p>
//           </div>
//           <Link
//             to={`${ROUTE_TOURNAMENT}`}
//             className="px-20 shadow-rainbow btn-white w-fit"
//           >
//             {t('play')}
//           </Link>
//         </div>
//       </section>
//     </main>
//   );
// });

// export default MainPage;

import {
  memo, useContext, useEffect,
} from 'react';
import { ROUTE_TOURNAMENT } from '@constants/constants';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '@context/AuthContext';
import { useTranslation } from 'react-i18next';
import './MainPage.scss';
import mainMysteryBoxImage from '@img/mystery-5-min.png';
import mainFirstNewsImage from '@img/main-first-news-image.jpeg';
import mainSecondNewsImage from '@img/main-second-news-image.png';
import mysteryGroupImage from '@img/mystery-group-image.png';
import secondCollectionImage from '@img/second-collection-main.png';
import thirdCollectionImage from '@img/third-collection-main.png';
import mainNewsBgImageFirst from '@img/main-news-bg-image-first.jpg';
import mainNewsBgImageSecond from '@img/main-news-bg-image-second.jpg';
import mainNewsBgImageSecondForMobile from '@img/main-news-second-mobile-bg.png';
import TennisBall from '@img/tennis-ball-main-addition.svg';
import BallImage from '@img/ball.png'

import videoBg from '@img/video-bg.mp4'
import Header from '@screens/Header';

const MainPage = memo(() => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user?.is_learned) {
      navigate(`${ROUTE_TOURNAMENT}`, { replace: true });
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <main className="main">
    <section className="main__hero-head">
      {/* <Header /> */}
      <div className="main__hero">
      {/* <div className="main__hero-shadow_block-top"></div> */}
      <div className="video-background">
        <video autoPlay muted loop>
            <source src={videoBg} type="video/mp4" />
            Ваш браузер не поддерживает видео.
        </video>
      </div>
      <h1 className="main__title-block">
        <span className="main__title-addition">
          NFT
          {' '}
        </span>
        Tenn<span className='main__title-letter_ball'>i<img src={BallImage} className="main__title-ball" /></span><span>s</span>
      </h1>
      <p className="main__description">
        {t('main.description')}
      </p>
      {/* <marquee className="marquee">NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis NFT-Tennis</marquee> */}

      <div className="main__button-outline">
        <button className="main__button main__button-outline_child" onClick={() => navigate('/my_teams')}>
          {t('main.create_team')}
        </button>
      </div>
      <div className="main__hero-shadow_block"></div>
      </div>
    </section>
    <section className="main__cards">
      <div className="main__cards-container">
        <div className="main__cards-title_block">
          <TennisBall className="tennis-ball-main tennis-ball-page" />
          <h2 className="main__cards-title">
            <span>
              {t('main.create')}
              {' '}
            </span>
            <span className="main__cards-title_gradient">{t('main.dream_team')}</span>
          </h2>
          <p className="main__cards-description">
            {t('main.create_team_description')}
          </p>
        </div>
        <div className="main__cards-bg" />
        {/* <img
            src={mainCardsGroup}
            alt=""
            className="main__cards-group"
          /> */}
      </div>
    </section>

    <section className="main__ads">
      <div className="container main__ads-container" data-aos="fade-up">
        <h2 className="section-title">
          <span>
            <TennisBall className="tennis-ball-ads tennis-ball-page" />
            {t('main.be_owner_club')}
          </span>
        </h2>
        <ul className="main__ads-list">
          <li className="main__ads-item">
            <img src={mainMysteryBoxImage} alt="mystery" className="main__ads-item_image main__ads-item_image_first" />
            <div className="main__ads-item_title-block">
              <h3 className="main__ads-item_title">
                {t('main.be_collector')}
              </h3>
              <p className="main__ads-item_description">
                {t('main.be_collector_description')}
              </p>
            </div>
          </li>
          <li className="main__ads-item">
            <img src={secondCollectionImage} alt="mystery" className="main__ads-item_image main__ads-item_image_second" />
            <div className="main__ads-item_title-block">
              <h3 className="main__ads-item_title">
                {t('main.compete_with_others')}
              </h3>
              <p className="main__ads-item_description">
                {t('main.compete_with_others_description')}
              </p>
            </div>
          </li>
          <li className="main__ads-item active">
            <img src={thirdCollectionImage} alt="mystery" className="main__ads-item_image main__ads-item_image_third" />
            <div className="main__ads-item_title-block">
              <h3 className="main__ads-item_title">
                {t('main.you_champion')}
                !
              </h3>
              <p className="main__ads-item_description">
                {t('main.you_champion_description')}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section className="main__news">
      <div className="container main__news-container" data-aos="zoom-in">
        <ul className="main__news-list">
          <li className="main__news-item main__news-item_first">
            <div className="main__news-item_title-block main__news-item_title-block_first">
              <h3 className="main__news_item-title">
                {t('main.news_first_title')}
              </h3>
              <p className="main__news_item-description">
                {t('main.news_first_description')}
              </p>
            </div>
            <img src={mainFirstNewsImage} alt="" className="main__news_item-image main__news-image_shared" />
          </li>
          <li className="main__news-item main__news-item_third">
            <img
              src={mainNewsBgImageSecond}
              alt=""
              className="main__news_item-image main__news_item-image_third"
            />
            <img src={mainNewsBgImageSecondForMobile} alt="" className="main__news_item-image main__news_item-image_phone" />
            <div className="main__news_item-content_third">
              <h3 className="main__news_item-title main__news_item-title_third">
                {t('main.news_second_title')}
              </h3>
              <p className="main__news_item-description main__news_item-description_third">
                {t('main.news_second_description')}
              </p>
            </div>
            <img
              src={mainNewsBgImageFirst}
              alt=""
              className="main__news_item-image main__news_item-image_third"
            />
          </li>
          <li className="main__news-item main__news-item_last">
            <img src={mainSecondNewsImage} alt="" className="main__news_item-image main__news_item-image_second main__news-image_shared" />
            <div className="main__news-item_title-block">
              <h3 className="main__news_item-title">
                {t('main.news_third_title')}
              </h3>
              <p className="main__news_item-description main__news_item-description_third_text">
                {t('main.news_third_description')}
              </p>
              <p className="main__news_item-description">
                {t('main.news_third_description_second')}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section className="main__mystery">
      <div className="main__mystery-container">
        <img src={mysteryGroupImage} className="main__mystery-image" />
        <div className="main__mistery_text-block">
          <h3 className="main__mystery-title">
            {t('main.open_mystery')}
          </h3>
          <p className="main__mystery-description">
            {t('main.develop_team')}
          </p>
          <div className="main__button-outline main__mystery-outline">
            <button className="main__button main__button-outline_child main__mystery-button" onClick={() => navigate('/market/box')}>
              {t('market.open_box')}
            </button>
          </div>
        </div>
      </div>
    </section>

    <section className="main__articles">
      <div className="container main__articles-container">
        <div className="main__articles_title-block">
          <h3 className="main__articles-title">
            <span>
              <TennisBall className="tennis-ball-articles tennis-ball-page" />
              {t('main.nft_tennis_news')}
            </span>
          </h3>
          <p className="main__articles-description">
            {t('main.news_description')}
          </p>
        </div>
        <div className="main__articles-list_wrapper">
          <ul className="main__articles-list">
            <li className="main__articles-item main__articles-item_first">
              <div className="main__articles-item_text-block">
                <h3 className="main__articles-item_title">
                  {t('main.first_article_title')}
                </h3>
                <p className="main__articles-item_description">
                  {t('main.first_article_description')}
                </p>
                <Link to="/login" className="main__articles-item_link">{t('details')}</Link>
              </div>
            </li>
            <li className="main__articles-item main__articles-item_second">
              <div className="main__articles-item_text-block">
                <h3 className="main__articles-item_title">
                  {t('main.second_article_title')}
                </h3>
                <p className="main__articles-item_description">
                  {t('main.second_article_description')}
                </p>
                <Link
                  to="/market/cards"
                  className="main__articles-item_link">
                    {t('details')}
                </Link>
              </div>
            </li>
            <li className="main__articles-item main__articles-item_third">
              <div className="main__articles-item_text-block">
                <h3 className="main__articles-item_title">
                  {t('main.third_article_title')}
                </h3>
                <p className="main__articles-item_description">
                  {t('main.third_article_description')}
                </p>
                <Link to="#" className="main__articles-item_link">{t('details')}</Link>
              </div>
            </li>
            <li className="main__articles-item main__articles-item_fourth">
              <div className="main__articles-item_text-block">
                <h3 className="main__articles-item_title">
                  {t('main.fourth_article_title')}
                </h3>
                <p className="main__articles-item_description">
                  {t('main.fourth_article_description')}
                </p>
                <Link to="/login" className="main__articles-item_link">{t('details')}</Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
  </>
  );
});

export default MainPage;
